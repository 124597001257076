import React from "react"
import { Helmet } from "react-helmet"
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"

import MainLayout from "../../Layouts/layoutLanding"
import MobileBooking from "./MobileBooking"

const MobileCheckout = () => {
  const client = new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>
      <Helmet>
        {
          <noscript>
            {`
              <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=860210341343925&ev=PageView
          &noscript=1"
            />`}
          </noscript>
        }
        {
          <script
            src="https://www.googletagmanager.com/gtag/js?id=UA-56517066-2"
            integrity="sha384-31gnlZFdixRuOKhMVy+gW1hHxMLGeGbIy8CbxGc1dioGt083cMfHbYgRLewtIC2p"
            crossOrigin="anonymous"
          ></script>
        }
        {
          <script>
            {`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'UA-56517066-2');
  `}
          </script>
        }
      </Helmet>
      <MobileBooking />
    </ApolloProvider>
  )
}

export default MobileCheckout
